import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/seo"
import MidwivesContent from "../components/midwives/midwives-content";

const MidwivesPage = () => (
    <Layout showFooter={true}>
        <SEO title="Hi Midwives!" />
        <MidwivesContent />
    </Layout>
)

export default MidwivesPage
