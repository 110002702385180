import React from "react"
import styled from "styled-components"
import headerImage from "../../images/midwives_bg.jpg"
import NextSectionButton, {Link} from "../common/sections/next-section-button";
import PageSection from "../common/sections/page-section";
import EmphasizedSection from "../common/sections/emphasized-section";
import recordImage from "../../images/fidu-frame-record-midwife.png"
import mothersList from "../../images/fidu-frame-mothers.png"
import fiduAppWithLogo from "../../images/fidu-frame-logo.png"
import {COLORS, device} from "../../styles/constants";

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem 0rem 1rem;
    max-width: 960px;
    width: 100%;
    margin: 60px auto 0 auto;
    justify-content: space-around;
    flex: 1;
`

const CenteredContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 2rem;
    max-width: 960px;
    margin: 0 auto;
    flex: 1;
`

const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    
    .image {
        flex: 1;
        margin-top: 50px;
    }
    
    .text {
        flex: 1;
        padding-right: 2rem;
    }
    
    .text > .button {
        display: none;
    }
    
    @media ${device.tablet} { 
         flex-direction: row;
        .image {
            flex: 0.5;
            margin-top: 0;
        }
        .text {
            flex: 0.5;
        }
        .text > .button {
            display: block;
        }
        .button {
            display: none;
        }
    }
`

const StyledImage = styled.img`
    height: 600px;
`

const Hero = () => (
    <PageSection backgroundImage={headerImage} first={true}>
        <HeroContainer>
            <div>
                <h1 style={{
                    textAlign: "left",
                    maxWidth: 440,
                    fontWeight: "bold",
                    fontSize: "3.5rem"
                }}>Hi midwives!</h1>
                <p style={{
                    textAlign: "left",
                    maxWidth: 440,
                    fontWeight: 500,
                    fontStyle: "italic",
                    fontSize: "1.4rem",
                    lineHeight: "1.9"
                }}>
                    your job is about life.<br/>
                    you guide.<br/>
                    thanks.
                </p>
            </div>
            <div style={{
                maxWidth: 600,
            }}>
                <p style={{
                    textAlign: "left",
                    fontWeight: 500,
                    fontSize: "1.3rem"
                }}>FIDU - your maternity manager.</p>
                <p style={{
                    textAlign: "left",
                    fontWeight: 500,
                    fontSize: "0.8rem"
                }}>With the FIDU maternity record on your side you are able
                    to focus on what's important, your expecting mothers. Stay informed
                    about your mothers' and their baby's health, stay connected with
                    them at any time. FIDU helps you to get organized, so you can act fast if needed.<br/><br/>
                    <i>This is what we call high quality care.</i>
                </p>
                <NextSectionButton target="page-1">show me more</NextSectionButton>
            </div>
        </HeroContainer>
    </PageSection>
)

const Content = () => (
    <>
        <PageSection id="page-1">
            <EmphasizedSection
                text="We want you to be able to focus on what's important. Your expecting mothers."
            />
            <CenteredContainer>
                <FeatureContainer>
                    <div className="text">
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "2rem",
                            color: `${COLORS.purple}`
                        }}>Everything in one place.</p>
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "1.4rem",
                            color: `${COLORS.purple}`
                        }}><i>Everywhere you go.</i></p>
                        <br/>
                        <p style={{
                            textAlign: "left",
                            fontWeight: "lighter",
                            lineHeight: "1.8rem"
                        }}>With FIDU you have your mothers always with you.
                            All your mothers' pregnancy related health data is just one click away,
                            everywhere you go, at any time. Finish all the documentation and administrative work
                            directly in FIDU on the way of during an appointment. Bye paperwork. Hello, freedom.<br/><br/>
                            <i>You deserve to be able to focus on what's important.</i>
                        </p>
                        <div className="button">
                            <NextSectionButton target="page-2">show me more</NextSectionButton>
                        </div>
                    </div>
                    <div className="image">
                        <StyledImage src={mothersList}/>
                    </div>
                    <div className="button">
                        <NextSectionButton target="page-2">show me more</NextSectionButton>
                    </div>
                </FeatureContainer>
            </CenteredContainer>
        </PageSection>
        <PageSection id="page-2">
            <EmphasizedSection
                text="We want to help you to deliver the high quality care for your mothers."
            />
            <CenteredContainer>
                <FeatureContainer>
                    <div className="text">
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "2rem",
                            color: `${COLORS.purple}`
                        }}>Stay connected.</p>
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "1.4rem",
                            color: `${COLORS.purple}`
                        }}><i>Communicate effectively.</i></p>
                        <br/>
                        <p style={{
                            textAlign: "left",
                            fontWeight: "lighter",
                            lineHeight: "1.8rem"
                        }}>Use FIDU with to communicate with your mothers. Send them notifications about important
                            milestones in their pregnancy, book appointments, answer urgent questions or
                            access ultrasound scans and lab results. Everything in one place.<br/><br/>
                            <i>Simple communication, better care.</i>
                        </p>
                        <div className="button">
                            <NextSectionButton target="page-3">show me more</NextSectionButton>
                        </div>
                    </div>
                    <div className="image">
                        <StyledImage src={recordImage}/>
                    </div>
                    <div className="button">
                        <NextSectionButton target="page-3">show me more</NextSectionButton>
                    </div>
                </FeatureContainer>
            </CenteredContainer>
        </PageSection>
        <PageSection id="page-3" last={true}>
            <EmphasizedSection
                text="We truly believe that you deserve to be able to do your job without distractions."
            />
            <CenteredContainer>
                <FeatureContainer>
                    <div className="text">
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "2rem",
                            color: `${COLORS.purple}`
                        }}>Act fast.</p>
                        <p style={{
                            textAlign: "left",
                            fontWeight: 440,
                            fontSize: "1.4rem",
                            color: `${COLORS.purple}`
                        }}><i>Get organized.</i></p>
                        <br/>
                        <p style={{
                            textAlign: "left",
                            fontWeight: "lighter",
                            lineHeight: "1.8rem"
                        }}>Having several mothers to care for at the same time is hard and can get messy.
                            With FIDU you have a tool where everything is organized. If a mother goes into labor at
                            3am, with FIDU you have all the information you need to act fast.<br/><br/>
                            <i>You are in full control.</i>
                        </p>
                        <p style={{
                            textAlign: "center",
                            fontWeight: 440,
                            color: `${COLORS.purple}`,
                            fontSize: "2rem",
                            alignSelf: "center",
                            paddingTop: 50,
                            paddingBottom: 20
                        }}>Interested? Get in touch!</p>
                        <div style={{
                            textAlign: "center",
                            fontWeight: 440,
                            color: `${COLORS.purple}`,
                            alignSelf: "center",
                            paddingBottom: 50
                        }}><Link href="mailto:office@fidu.health">office@fidu.health</Link></div>
                    </div>
                    <div className="image">
                        <StyledImage src={fiduAppWithLogo}/>
                    </div>
                </FeatureContainer>
            </CenteredContainer>
        </PageSection>
    </>

)

const MidwivesContent = () => {
    return (<>
        <Hero />
        <Content />
    </>);
}

export default MidwivesContent;