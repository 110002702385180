import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {FOOTER_HEIGHT, HEADER_HEIGHT} from "../../../styles/constants";

const BackgroundImage = styled.div`
    background-image: url(${props => props.backgroundImage});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: ${props => props.first ? 0 : HEADER_HEIGHT}px;
    left: 0;
    z-index: -5;
    min-height: calc(100vh + ${HEADER_HEIGHT}px);
    width: 100%;
    opacity: 0.5;
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: ${props => props.last ? `calc(100vh - ${FOOTER_HEIGHT}px)` : `100vh`};
    position: relative;
    padding-top: ${props => props.first ? 0 : HEADER_HEIGHT}px;
`


const PageSection = ({ id, children, siteTitle, backgroundImage, first = false, last = false }) => (
    <>
        <ContentWrapper first={first} last={last} id={id}>
            {backgroundImage && <BackgroundImage first={first} backgroundImage={backgroundImage}/>}
            {children}
        </ContentWrapper>
    </>
)

PageSection.propTypes = {
    siteTitle: PropTypes.string,
    backgroundImage: PropTypes.string,
}

PageSection.defaultProps = {
    siteTitle: ``,
    backgroundImage: ``
}

export default PageSection
