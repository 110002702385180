import React from "react"

import { COLORS } from "../../../styles/constants"
import CenteredContainer from "../layout/centered-container";

const EmphasizedSection = ({ text, id }) => (
    <div id={id} style={{
        background: COLORS.purple,
        width: "100%",
        padding: "2rem"
    }}>
        <CenteredContainer>
            <p style={{
                fontSize: "large",
                color: COLORS.lightWhite,
                marginBottom: 0,
                lineHeight: 1.8
            }}>{text}</p>
        </CenteredContainer>
    </div>
)

export default EmphasizedSection
