import React, {Children} from "react"

import { COLORS } from "../../../styles/constants"

const CenteredContainer = ({ children }) => (
  <div style={{
      padding: 0,
      margin: 0,
      maxWidth: 960,
      width: "100%",
      height: "100%",
      marginLeft: "auto",
      marginRight: "auto"
  }}>
      {children}
  </div>
)

export default CenteredContainer
