import React from "react"

import { COLORS, BORDER_RADIUS } from "../../../styles/constants"
import styled from "styled-components";

export const Link = styled.a`
    padding: .5rem 2.5rem;
    color: ${COLORS.lightWhite};
    font-weight: 700;
    background: ${COLORS.pink};
    border-radius: ${BORDER_RADIUS};
    border-width: 0;
    cursor: pointer;
    width: fit-content;
    text-decoration:none;
    box-shadow: 0px 5px 20px -2px rgba(70, 70, 122, 0.275);
    transition: all 0.3s ease-in-out;
    
    &:hover {
      box-shadow: 0px 5px 20px -2px rgba(70, 70, 122, 0.8);
    }
    
    &:active {
      box-shadow: 0px 5px 20px -2px rgba(70, 70, 122, 0);
      transition: all 0s ease-in-out;
    }
    
`

const NextSectionButton = ({ children, target }) => {

  const onClick = (e) => {
    e.preventDefault();
    const element = document.querySelector("#"+target);
    if(element){
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
      <Link href={`#${target}`} onClick={onClick}>
        {children}
      </Link>
  );

}

export default NextSectionButton
